import { React, useState,useEffect } from "react";
import "./style.css";
import MostValuabe_Player from "./MostValuablePlayer";
import IndividualAward from "./IndividualAward";
import CoreValue from "./CoreValue";
import Jury_Award from "./JuryAward";
import Managed_Service from "./ManagedServices";
import Sale_Awards from "./Sales_Award";
import Shared_Services from "./SharedServices";
import Tm_Support from "./TmSupportProjectStarPerformer";
import True_Champion from "./TrueChampion";
import Choice_Awards from "./choice";
import Project_star from "./ProjectAwardStar";
import Project_Fixed from "./ProjectAwardFixed";
import Project_Stratagic from "./ProjectAwardStarategic";
import "./scroll.css";
import ProjectStaffAwards  from "./ProjectStaffAwards";
import PerformanceManagementSystem from "./PerformanceManagementSystem";

const FullpageScroll = (props) => {
  const [size, setSize] = useState( window.innerWidth);

   useEffect(() => {
      const handleResize = () => {
        setSize( window.innerWidth)
      } 
      window.addEventListener("resize", handleResize)
    }, [])

  const {
    results,
    choices,
    coreValueAward,
    individualAward,
    juryAward,
    ManagedService,
    MostValuabePlayers,
    projectAwardStarPerformer,
    projectAwardStarFixedPerformer,
    projectAwardsStaffAugTMStarPerformers,
    projectAwardStarStratagicPerformers,
    salesAwards,
    sharedService,
    tmSupportProjectsStarPerformers,
    trueChampions,
    performanceManagementSystem

  } = props;


  const sectionStyle = {
    height: "100vh",
    width: "100vw",
    display: "Flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    // backgroundImage : `url(${Background1})`
    font: "white",
  };

  const Corevalue = () => {
    return;
  };
  
  return (
    <div className="wrapper">
      <section>
      <Choice_Awards choices={choices} width={size} />
      </section>
      <section>
      <IndividualAward individualAward={individualAward} width={size}/>
      </section>
      <section>
      <Project_star projectAwardStarPerformer={projectAwardStarPerformer} width={size} />
      </section>
      <section>
      <Shared_Services sharedService={sharedService} width={size}/>
      </section>
      <section>
      <Project_Fixed projectAwardStarFixedPerformer={projectAwardStarFixedPerformer} width={size}/>
      </section>
      <section>
      <ProjectStaffAwards projectAwardsStaffAugTMStarPerformers={projectAwardsStaffAugTMStarPerformers} width={size}/>
      </section>
      <section>
      <Project_Stratagic projectAwardStarStratagicPerformers={projectAwardStarStratagicPerformers } width={size}/>
      </section>
      <section>
      <Tm_Support
        tmSupportProjectsStarPerformers={tmSupportProjectsStarPerformers} width={size}
      />
      </section>
      <section>
      <Managed_Service ManagedService={ManagedService} width={size}/>
      </section>
      <section>
      <PerformanceManagementSystem performanceManagementSystem={performanceManagementSystem} width={size}/>
      </section>
      <section>
      <CoreValue coreValueAward={coreValueAward} width={size}/>
      </section>
      <section>
      <Jury_Award juryAward={juryAward} width={size} />
      </section>
      <section>
      <Sale_Awards salesAwards={salesAwards} width={size} />
      </section>
      <section>
      <True_Champion trueChampions={trueChampions} width={size}/>
      </section>
      <section>
      <MostValuabe_Player MostValuabePlayers={MostValuabePlayers} width={size}/>
      </section>
    </div>
  );
};

export default FullpageScroll;
